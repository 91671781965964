import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Input, Button, List, Avatar, Spin } from 'antd';
import { LoadingOutlined, LikeOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const ChatWindow = ({ userData, setUserData, setAnswers }) => {
    const [input, setInput] = useState('');
    const [messages, setMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [finalMessage, setFinalMessage] = useState('');
    const [conversationSummary, setConversationSummary] = useState(null); // To store summary data
    const [conversationData, setConversationData] = useState(null); // To store full conversation data
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [userResponses, setUserResponses] = useState({});
    const [isChatOpen, setIsChatOpen] = useState(false); // This will control if we are in free chat mode
    const [isConversationEnded, setIsConversationEnded] = useState(false); // To handle when the conversation ends
    const inputRef = useRef(null);  // Input ref to auto-focus the input field
    const messageListRef = useRef(null);

    const questions = [
        "Mikä on nimesi?",
        "Mikä on puhelinnumerosi?",
        "Mikä on sähköpostiosoitteesi?",
        "Valitse palvelukokonaisuus (vaihtoehdot: Rakennesuunnittelu, Mekaniikkasuunnittelu, Sähkösuunnittelu, Prosessisuunnittelu, Lujuuslaskenta, Useiden palveluiden yhdistelmä, Muu, mikä?, En osaa sanoa):",
        "Kuvaile hanketta vapaasti:",
        "Mikä on hankkeen aloituspäivä? (esim. 01.01.2024)",
        "Mikä on hankkeen toivottu päättymispäivä? (esim. 31.12.2024)",
        "Onko projektissa mitään, mikä menee standardit ja lainsäädännöt alle?"
    ];

    const handleEndConversation = async () => {
        if (!userData.userId) {
            return alert('Keskustelua ei ole vielä aloitettu.');
        }

        try {
            setIsLoading(true);

            // Call the end conversation API
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v2/end-conversation`, { userId: userData.userId });

            if (response.status === 200) {
                setFinalMessage('Kiitos keskustelusta. Keskustelu on päättynyt.');

                // Assume the backend returns the original conversation data and summary
                const { originalData, summary } = response.data;

                // Set the original conversation data and summary to be displayed
                setConversationData(originalData);
                setConversationSummary(summary);

                // Mark the conversation as ended
                setIsConversationEnded(true);

                setMessages(prevMessages => [
                    ...prevMessages,
                    { sender: 'bot', message: 'Keskustelu on päättynyt.' }
                ]);
            }
        } catch (error) {
            console.error('Failed to end conversation:', error);
            alert('Virhe keskustelun lopettamisessa. Yritä uudelleen.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleRestartConversation = () => {
        // Reset all states to start a new conversation
        setInput('');
        setMessages([]);
        setUserResponses({});
        setCurrentQuestion(0);
        setConversationSummary(null);
        setConversationData(null);
        setFinalMessage('');
        setIsConversationEnded(false);
        setIsChatOpen(false);

        // Set initial question
        setMessages([{ sender: 'bot', message: questions[0] }]);
    };

    useEffect(() => {
        // Set the first question when the component loads
        setMessages([{ sender: 'bot', message: questions[currentQuestion] }]);
    }, []);

    useEffect(() => {
        if (messageListRef.current) {
            messageListRef.current.scrollTo({
                top: messageListRef.current.scrollHeight,
                behavior: 'smooth'
            });
        }
    }, [messages]);

    useEffect(() => {
        if (!isLoading && inputRef.current && !isConversationEnded) {
            inputRef.current.focus();
        }
    }, [isLoading, isConversationEnded]);

    const handleInputChange = (e) => {
        setInput(e.target.value);
    };

    const handleSendMessage = async (event) => {
        event.preventDefault();
        if (!input.trim()) return;

        const newResponses = { ...userResponses, [questions[currentQuestion]]: input };
        setUserResponses(newResponses);

        setMessages(prevMessages => [
            ...prevMessages,
            { sender: 'user', message: input }
        ]);

        setInput('');
        setIsLoading(true);

        setTimeout(() => {
            if (currentQuestion < questions.length - 1) {
                setCurrentQuestion(currentQuestion + 1);
                setMessages(prevMessages => [
                    ...prevMessages,
                    { sender: 'bot', message: questions[currentQuestion + 1] }
                ]);
            } else {
                processUserData(newResponses);
                setIsChatOpen(true);
            }
            setIsLoading(false);
        }, 1500);
    };

    const handleUserResponse = async (event) => {
        event.preventDefault();
        if (!input.trim()) return;

        setIsLoading(true);
        try {
            const { userId } = userData;

            // Append the new user message to the conversation history
            setMessages(prevMessages => [
                ...prevMessages,
                { sender: 'user', message: input }
            ]);

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v2/user-response`, {
                userId,
                message: input,
                conversationHistory: messages // Send the entire conversation history
            });

            setMessages(prevMessages => [
                ...prevMessages,
                { sender: 'bot', message: response.data.response }
            ]);
        } catch (error) {
            console.error('Failed to process user response:', error);
            alert('Error processing response. Please try again.');
        } finally {
            setInput('');
            setIsLoading(false);
        }
    };

    const processUserData = async (responses) => {
        setIsLoading(true);
        try {
            const formattedResponses = {
                name: responses[questions[0]],
                phoneNumber: responses[questions[1]],
                email: responses[questions[2]],
                servicePackage: responses[questions[3]],
                projectDescription: responses[questions[4]],
                startDate: responses[questions[5]],
                endDate: responses[questions[6]],
                standards: responses[questions[7]],
                conversationHistory: messages,
            };

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/start-conversation`, formattedResponses);
            if (response.data && response.data.userId) {
                const fullUserData = {
                    ...userData,
                    ...formattedResponses,
                    userId: response.data.userId
                };
                setUserData(fullUserData);
                setMessages(prevMessages => [
                    ...prevMessages,
                    { sender: 'bot', message: 'Kaikki tiedot on kerätty. Nyt voit keskustella kanssamme vapaasti.' }
                ]);
            }
        } catch (error) {
            console.error('Error starting conversation:', error);
            alert('Error communicating with the server.');
        } finally {
            setIsLoading(false);
        }
    };

    const renderMessageItem = (item) => (
        <List.Item>
            <List.Item.Meta
                avatar={<Avatar style={{ backgroundColor: item.sender === 'user' ? '#f56a00' : '#87d068' }} icon={item.sender === 'user' ? 'user' : 'robot'} />}
                title={item.sender === 'user' ? 'Vastauksesi' : 'OutoBot'}
                description={<p>{item.message}</p>}
            />
        </List.Item>
    );

    return (
        <div id="wrap">
            <div className="header">
                <div className="inner-container">
                    <h2>Outomaatti</h2>
                </div>
            </div>

            
        

            <div className="chat-window">
                {!isConversationEnded && (
                <div className="message-list" ref={messageListRef}>
                    <List dataSource={messages} renderItem={renderMessageItem} />
                </div>
                )}
                <div className="message-field">
                    <Spin spinning={isLoading} indicator={antIcon}>
                        {/* Disable input field and button if the conversation has ended */}
                        {!isConversationEnded ? (
                            <form onSubmit={isChatOpen ? handleUserResponse : handleSendMessage} className="message-form">
                                <Input
                                    ref={inputRef}
                                    type="text"
                                    value={input}
                                    onChange={handleInputChange}
                                    placeholder="Kirjoita vastauksesi..."
                                    onPressEnter={isChatOpen ? handleUserResponse : handleSendMessage}
                                    disabled={isLoading || isDisabled || isConversationEnded}
                                />
                                <Button className="sendButton" onClick={isChatOpen ? handleUserResponse : handleSendMessage} disabled={isDisabled || !input.trim() || isLoading || isConversationEnded}>
                                    Lähetä viesti
                                </Button>
                            </form>
                        ) : (
                           <></>
                        )}
                    </Spin>

                    {!isConversationEnded && userData.userId && (
                        <Button type="danger" onClick={handleEndConversation} disabled={isLoading || isConversationEnded || !userData.userId}>
                            Lopeta keskustelu
                        </Button>
                    )}
                    
     
                    {finalMessage && <div className="final-message">
                        {/* <div className="icon"><LikeOutlined style={{ color: 'green', fontSize: 150 }} /></div> */}
                        <div className="message">

                            <Button type="primary" onClick={handleRestartConversation}>
                                Aloita uusi keskustelu
                            </Button>
                            
                            
                            <p>{finalMessage}</p>
                            {conversationData && conversationSummary && (

                                <>
                                    <p>Tiedot ylläpitäjälle. Poistetaan viimeisestä versiosta:</p>
                            <pre>{JSON.stringify(conversationData, null, 2)}</pre>
                                    <pre>{conversationSummary}</pre>
                                </>
                            )}
                        
                        </div>
                    </div>}

                  
                </div>
                </div>
       
        </div>
    );
};

export default ChatWindow;
