import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Input, Button, List, Avatar, Spin, Select, DatePicker } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const ChatWindow = ({ userData, setUserData, setAnswers }) => {
    const [input, setInput] = useState('');
    const [messages, setMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [finalMessage, setFinalMessage] = useState('');
    const [conversationSummary, setConversationSummary] = useState(null); // To store summary data
    const [conversationData, setConversationData] = useState(null); // To store full conversation data
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [userResponses, setUserResponses] = useState({});
    const [isChatOpen, setIsChatOpen] = useState(false); // This will control if we are in free chat mode
    const [isConversationEnded, setIsConversationEnded] = useState(false); // To handle when the conversation ends
    const inputRef = useRef(null);  // Input ref to auto-focus the input field
    const messageListRef = useRef(null);

    const questions = [
        "Mikä on nimesi?",
        "Mikä on puhelinnumerosi?",
        "Mikä on sähköpostiosoitteesi?",
        "Valitse palvelukokonaisuus:",
        "Kuvaile hanketta vapaasti:",
        "Mikä on hankkeen aloituspäivä?",
        "Mikä on hankkeen toivottu päättymispäivä?",
        "Onko projektissa mitään, mikä menee standardit ja lainsäädännöt alle?"
    ];

    const serviceOptions = [
        'Rakennesuunnittelu',
        'Mekaniikkasuunnittelu',
        'Sähkösuunnittelu',
        'Prosessisuunnittelu',
        'Lujuuslaskenta',
        'Useiden palveluiden yhdistelmä',
        'Muu, mikä?',
        'En osaa sanoa'
    ];

    

    const dropdownQuestionIndex = 3; // Index of the dropdown question
    const dateQuestionIndices = [5, 6]; // Indices of the date picker questions

    // Indices of the questions to validate using AI
    const aiValidatedQuestions = [0, 1, 2, 4, 7]; // Indices of the specified questions

    const handleEndConversation = async () => {
        if (!userData.userId) {
            return alert('Keskustelua ei ole vielä aloitettu.');
        }

        try {
            setIsLoading(true);

            // Call the end conversation API
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v2/end-conversation`, { userId: userData.userId });

            if (response.status === 200) {
                setFinalMessage('Kiitos keskustelusta. Keskustelu on päättynyt.');

                // Assume the backend returns the original conversation data and summary
                const { originalData, summary } = response.data;

                // Set the original conversation data and summary to be displayed
                setConversationData(originalData);
                setConversationSummary(summary);

                // Mark the conversation as ended
                setIsConversationEnded(true);

                setMessages(prevMessages => [
                    ...prevMessages,
                    { sender: 'bot', message: 'Keskustelu on päättynyt.' }
                ]);
            }
        } catch (error) {
            console.error('Failed to end conversation:', error);
            alert('Virhe keskustelun lopettamisessa. Yritä uudelleen.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleRestartConversation = () => {
        // Reset all states to start a new conversation
        setInput('');
        setMessages([]);
        setUserResponses({});
        setCurrentQuestion(0);
        setConversationSummary(null);
        setConversationData(null);
        setFinalMessage('');
        setIsConversationEnded(false);
        setIsChatOpen(false);

        // Set initial welcome message and delay first question
        setMessages([{ sender: 'bot', message: 'Tervetuloa Outomaattiin! Ennen kuin aloitamme, tarvitsen muutamia tietoja, kenen kanssa puhun.' }]);

        // Delay the display of the first question
        setTimeout(() => {
            const firstQuestion = {
                sender: 'bot',
                message: questions[0],
                type: 'text'
            };
            setMessages(prevMessages => [
                ...prevMessages,
                firstQuestion
            ]);
        }, 2000); // Adjust the delay as needed
    };

    useEffect(() => {
        // Set the welcome message when the component loads
        setMessages([{ sender: 'bot', message: 'Tervetuloa Outomaattiin! Ennen kuin aloitamme, tarvitsen muutamia tietoja, kenen kanssa puhun.' }]);

        // Delay the display of the first question
        const timer = setTimeout(() => {
            const firstQuestion = {
                sender: 'bot',
                message: questions[currentQuestion],
                type: 'text'
            };
            setMessages(prevMessages => [
                ...prevMessages,
                firstQuestion
            ]);
        }, 2000); // Adjust the delay in milliseconds

        return () => clearTimeout(timer); // Cleanup the timer on unmount
    }, []);

    useEffect(() => {
        if (messageListRef.current) {
            messageListRef.current.scrollTo({
                top: messageListRef.current.scrollHeight,
                behavior: 'smooth'
            });
        }
    }, [messages]);

    useEffect(() => {
        if (!isLoading && inputRef.current && !isConversationEnded && (isChatOpen || shouldDisplayInputField())) {
            inputRef.current.focus();
        }
    }, [isLoading, isConversationEnded, isChatOpen, currentQuestion]);

    const handleInputChange = (e) => {
        setInput(e.target.value);
    };

    const handleOptionSelect = (value) => {
        const newResponses = { ...userResponses, [questions[currentQuestion]]: value };
        setUserResponses(newResponses);

        // Update the message to include the user's selection
        setMessages(prevMessages => {
            return prevMessages.map(msg => {
                if (msg.sender === 'bot' && msg.message === questions[currentQuestion]) {
                    return {
                        ...msg,
                        userSelected: true,
                        selectedValue: value
                    };
                }
                return msg;
            });
        });

        setIsLoading(true);

        setTimeout(() => {
            proceedToNextQuestion();
            setIsLoading(false);
        }, 1500);
    };

    const handleDateSelect = (date, dateString, question) => {
        const newResponses = { ...userResponses, [question]: dateString };
        setUserResponses(newResponses);

        // Update the message to include the user's selection
        setMessages(prevMessages => {
            return prevMessages.map(msg => {
                if (msg.sender === 'bot' && msg.message === question) {
                    return {
                        ...msg,
                        userSelected: true,
                        selectedValue: dateString
                    };
                }
                return msg;
            });
        });

        setIsLoading(true);

        setTimeout(() => {
            proceedToNextQuestion();
            setIsLoading(false);
        }, 1500);
    };

    const predefinedErrorMessages = {
        0: "Nimi ei ole kelvollinen. Voisitko yrittää antaa nimeäsi uudelleen?",
        1: "Puhelinnumero ei ole kelvollinen. Varmista, että annat oikean muotoisen numeron.",
        2: "Sähköposti ei ole kelvollinen. Ole hyvä ja anna oikea sähköpostiosoite.",
        4: "Hankkeen kuvaus ei vaikuta ymmärrettävältä. Yritä antaa tarkempi kuvaus.",
        7: "Vastaus ei ollut ymmärrettävä. Ole hyvä ja yritä uudelleen.",
    };
    
    const handleSendMessage = async (event) => {
        event.preventDefault();
        if (!input.trim()) return;
    
        // Append the user's message to the chat
        setMessages(prevMessages => [
            ...prevMessages,
            { sender: 'user', message: input }
        ]);
    
        setIsLoading(true);
    
        try {
            // Check if the current question needs AI validation
            if (aiValidatedQuestions.includes(currentQuestion)) {
                // Send the input to the backend for validation
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/validate-response`, {
                    question: questions[currentQuestion],
                    answer: input
                });
    
                const { isValid } = response.data;
    
                if (isValid) {
                    // If valid, save the response and proceed
                    const newResponses = { ...userResponses, [questions[currentQuestion]]: input };
                    setUserResponses(newResponses);
    
                    setInput('');
    
                    setTimeout(() => {
                        proceedToNextQuestion();
                        setIsLoading(false);
                    }, 1500);
                } else {
                    // If invalid, use the predefined error message
                    const errorMessage = predefinedErrorMessages[currentQuestion] || 'Vastauksesi ei ollut ymmärrettävä. Ole hyvä ja yritä uudelleen.';
                    setMessages(prevMessages => [
                        ...prevMessages,
                        { sender: 'bot', message: errorMessage }
                    ]);
                    setInput('');
                    setIsLoading(false);
                }
            } else {
                // For other questions, proceed as before
                const newResponses = { ...userResponses, [questions[currentQuestion]]: input };
                setUserResponses(newResponses);
    
                setInput('');
    
                setTimeout(() => {
                    proceedToNextQuestion();
                    setIsLoading(false);
                }, 1500);
            }
        } catch (error) {
            console.error('Validation error:', error);
            alert('Virhe vastauksen validoinnissa. Ole hyvä ja yritä uudelleen.');
            setInput('');
            setIsLoading(false);
        }
    };
    

    const proceedToNextQuestion = () => {
        if (currentQuestion < questions.length - 1) {
            setCurrentQuestion(currentQuestion + 1);

            const nextQuestionText = questions[currentQuestion + 1];
            let messageObj = { sender: 'bot', message: nextQuestionText, type: 'text' };

            if (currentQuestion + 1 === dropdownQuestionIndex) {
                messageObj.type = 'dropdown';
                messageObj.options = serviceOptions;
            } else if (dateQuestionIndices.includes(currentQuestion + 1)) {
                messageObj.type = 'date';
            }

            setMessages(prevMessages => [
                ...prevMessages,
                messageObj
            ]);
        } else {
            processUserData(userResponses);
            setIsChatOpen(true);
        }
    };

    const handleUserResponse = async (event) => {
        event.preventDefault();
        if (!input.trim()) return;

        setIsLoading(true);
        try {
            const { userId } = userData;

            // Append the new user message to the conversation history
            setMessages(prevMessages => [
                ...prevMessages,
                { sender: 'user', message: input }
            ]);

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v2/user-response`, {
                userId,
                message: input,
                conversationHistory: messages // Send the entire conversation history
            });

            setMessages(prevMessages => [
                ...prevMessages,
                { sender: 'bot', message: response.data.response }
            ]);
        } catch (error) {
            console.error('Failed to process user response:', error);
            alert('Error processing response. Please try again.');
        } finally {
            setInput('');
            setIsLoading(false);
        }
    };

    const processUserData = async (responses) => {
        setIsLoading(true);
        try {
            const formattedResponses = {
                name: responses[questions[0]],
                phoneNumber: responses[questions[1]],
                email: responses[questions[2]],
                servicePackage: responses[questions[3]],
                projectDescription: responses[questions[4]],
                startDate: responses[questions[5]],
                endDate: responses[questions[6]],
                standards: responses[questions[7]],
                conversationHistory: messages,
            };

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/start-conversation`, formattedResponses);
            if (response.data && response.data.userId) {
                const fullUserData = {
                    ...userData,
                    ...formattedResponses,
                    userId: response.data.userId
                };
                setUserData(fullUserData);
                setMessages(prevMessages => [
                    ...prevMessages,
                    { sender: 'bot', message: 'Kaikki tiedot on kerätty. Nyt voit keskustella kanssamme vapaasti.', type: 'text' }
                ]);
            }
        } catch (error) {
            console.error('Error starting conversation:', error);
            alert('Error communicating with the server.');
        } finally {
            setIsLoading(false);
        }
    };

    const shouldDisplayInputField = () => {
        // Determine if the input field should be displayed during the initial questions
        if (isChatOpen) return true;

        // If the current question is a dropdown or date picker, don't display the input field
        if (currentQuestion === dropdownQuestionIndex || dateQuestionIndices.includes(currentQuestion)) {
            return false;
        }

        return true;
    };

    const renderMessageItem = (item) => {
        if (item.sender === 'bot' && item.type === 'dropdown') {
            return (
                <List.Item>
                    <List.Item.Meta
                        avatar={<Avatar style={{ backgroundColor: '#87d068' }} icon='robot' />}
                        title='OutoBot'
                        description={
                            <>
                                <p>{item.message}</p>
                                {!item.userSelected ? (
                                    <Select
                                        style={{ width: '100%' }}
                                        placeholder="Valitse palvelukokonaisuus"
                                        onChange={handleOptionSelect}
                                    >
                                        {serviceOptions.map(option => (
                                            <Select.Option key={option} value={option}>{option}</Select.Option>
                                        ))}
                                    </Select>
                                ) : (
                                    <p><strong>Valitsit: </strong>{item.selectedValue}</p>
                                )}
                            </>
                        }
                    />
                </List.Item>
            );
        } else if (item.sender === 'bot' && item.type === 'date') {
            return (
                <List.Item>
                    <List.Item.Meta
                        avatar={<Avatar style={{ backgroundColor: '#87d068' }} icon='robot' />}
                        title='OutoBot'
                        description={
                            <>
                                <p>{item.message}</p>
                                {!item.userSelected ? (
                                    <DatePicker
                                        format="DD.MM.YYYY"
                                        style={{ width: '100%' }}
                                        onChange={(date, dateString) => handleDateSelect(date, dateString, item.message)}
                                    />
                                ) : (
                                    <p><strong>Valitsit: </strong>{item.selectedValue}</p>
                                )}
                            </>
                        }
                    />
                </List.Item>
            );
        } else {
            return (
                <List.Item>
                    <List.Item.Meta
                        avatar={<Avatar style={{ backgroundColor: item.sender === 'user' ? '#f56a00' : '#87d068' }} icon={item.sender === 'user' ? 'user' : 'robot'} />}
                        title={item.sender === 'user' ? 'Vastauksesi' : 'OutoBot'}
                        description={<p>{item.message}</p>}
                    />
                </List.Item>
            );
        }
    };

    return (
        <div id="wrap">
            <div className="header">
                <div className="inner-container">
                    <h2>Outomaatti</h2>
                </div>
            </div>

            <div className="chat-window">
                {!isConversationEnded && (
                    <div className="message-list" ref={messageListRef}>
                        <List dataSource={messages} renderItem={renderMessageItem} />
                    </div>
                )}
                <div className="message-field">
                    <Spin spinning={isLoading} indicator={antIcon}>
                        {/* Display input field during initial text questions and free chat */}
                        {!isConversationEnded && shouldDisplayInputField() && (
                            <form onSubmit={isChatOpen ? handleUserResponse : handleSendMessage} className="message-form">
                                <Input
                                    ref={inputRef}
                                    type="text"
                                    value={input}
                                    onChange={handleInputChange}
                                    placeholder="Kirjoita vastauksesi..."
                                    onPressEnter={isChatOpen ? handleUserResponse : handleSendMessage}
                                    disabled={isLoading || isDisabled || isConversationEnded}
                                />
                                <Button className="sendButton" onClick={isChatOpen ? handleUserResponse : handleSendMessage} disabled={isDisabled || !input.trim() || isLoading || isConversationEnded}>
                                    Lähetä viesti
                                </Button>
                            </form>
                        )}
                    </Spin>

                    {!isConversationEnded && userData.userId && (
                        <Button type="danger" onClick={handleEndConversation} disabled={isLoading || isConversationEnded || !userData.userId}>
                            Lopeta keskustelu
                        </Button>
                    )}

                    {finalMessage && <div className="final-message">
                        <div className="message">

                            <Button type="primary" onClick={handleRestartConversation}>
                                Aloita uusi keskustelu
                            </Button>

                            <p>{finalMessage}</p>
                            {conversationData && conversationSummary && (

                                <>
                                    <p>Tiedot ylläpitäjälle. Poistetaan viimeisestä versiosta:</p>
                                    <pre>{JSON.stringify(conversationData, null, 2)}</pre>
                                    <pre>{conversationSummary}</pre>
                                </>
                            )}

                        </div>
                    </div>}

                </div>
            </div>

        </div>
    );
};

export default ChatWindow;
